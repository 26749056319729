




















import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { ListFilter } from "@/models/entities/list-filter.interface";
import { WarningRequest } from "@/models/requests/warning-request.interface";
import { FILTERS_TITLE } from "@/helpers/filters-title";

@Component
export default class AnomaliesFilters extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private filters: { [id: string]: ListFilter[] };
  @PropSync("selected", { required: false, default: null, type: Object })
  private _selected: Omit<WarningRequest, "rows" | "page" | "search">;

  private get filtersScope(): string[] {
    return this.filters && Object.keys(this.filters).length !== 0
      ? Object.keys(this.filters)
      : [];
  }

  private getFilterTitle(scope: string): string {
    return FILTERS_TITLE(scope);
  }

  private onClick(value: string | null, scope: string): void {
    this.$set(this._selected, scope, value);
    this.$emit("on-click");
  }
}
